import 'magnific-popup';

jQuery( document ).ready(function($) {
    $('.gallery-image').magnificPopup({
        type: 'image'
    })

    gsap.registerPlugin(ScrollTrigger);

	$('.bg-trigger').each(function(){ 
		$('.bg-container').append('<div class="bg-section section-'+$(this).attr('id')+'"></div>');
		gsap.to(".section-"+$(this).attr('id'), {
			scale: 1.2,
			duration: 1,
			ease: Power1.easeIn,
			scrollTrigger: {
				trigger: "#"+$(this).attr('id'),
				start: "top center",
				end: "bottom 25%",
				scrub: true,
				markers: false,
				id: "scrub"+$(this).index()
			}
		});
		gsap.to(".section-"+$(this).attr('id'), {
			opacity: 0,
			duration: 0.1,
			ease: Power1.easeIn,
			scrollTrigger: {
				trigger: "#"+$(this).attr('id'),
				start: "bottom 80%",
				end: "bottom 30%",
				scrub: true,
				markers: false,
				id: "scrub"+$(this).index()+1
			}
		});
	})

});